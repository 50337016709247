import React from "react"
import styled from "styled-components"
import NotFound from "../images/404.png"

const Container = styled.div`
  height: 100vh;
  widows: 100vw;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotFoundPage = () => (
  <Container>
    <img alt="404" src={NotFound} />
  </Container>
)

export default NotFoundPage

